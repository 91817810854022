import * as React from 'react'

// Plugins
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image'

// Application
import HamburgerMenu from '../components/hamburger-menu'
import LanguageSwitcher from '../components/language-switcher'

// Styles
import { navWrapper, hamburgerMenuWrapper, languageSwitcherWrapper } from '../styles/header.module.css'

// Markup
const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <div className={navWrapper}>
          {/* Logo */}
          <Link to="/">
            <StaticImage
              src="../images/afn-logo.webp"
              alt="Advanced Fuel Nutrition logo"
              placeholder="none"
              height={40}
            />
          </Link>

          {/* Navigation links on tablet & up*/}
          <nav>
            <Link to="/products">{t('products')}</Link>
            <Link to="/contact">{t('contact')}</Link>
          </nav>
        </div>
        
        {/* Hamburger menu on mobile */}
        <div className={hamburgerMenuWrapper}>
          <HamburgerMenu></HamburgerMenu>
        </div>
        
        {/* Language switcher on desktop */}
        <div className={languageSwitcherWrapper}>
          <LanguageSwitcher />
        </div>       
      </header>
    </>
  )
}

export default Header;