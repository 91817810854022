import React, { useState } from "react"

// Plugins
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

// Styles
import { menu, menuIcon, closeButton } from '../styles/hamburger-menu.module.css'

// Markup
const HamburgerMenu = () => {
  const { t } = useTranslation();

  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <>
      {/* Hamburger menu icon */}
      <button className={menuIcon} onClick={() => handleMenuIconClick()}>menu</button>

      {/* Floating menu */}
      {menuOpened &&
        <div className={menu}>
          {/* Close button */}
          <button className={closeButton} onClick={() => handleMenuItemClick()}>close</button>

          {/* Navigation links */}
          <nav>
            <Link to="/" onClick={() => handleMenuItemClick()}>{t("home")}</Link>
            <Link to="/products" onClick={() => handleMenuItemClick()}>{t("products")}</Link>
            <Link to="/contact" onClick={() => handleMenuItemClick()}>{t("contact")}</Link>
          </nav>
        </div>
      }
    </>
  )

  // Hamburger menu icon click handler
  function handleMenuIconClick() {
    setMenuOpened(true);
  }

  // Close button / Menu item click handler
  function handleMenuItemClick() {
    setMenuOpened(false);
  }
}

export default HamburgerMenu