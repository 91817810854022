import React, { useState, useEffect } from "react";

// Styles
import { scrollToTopWrapper, arrowButton } from '../styles/scroll-to-top.module.css'

// Markup
const ScrollToTop = () => {
  // State for component's visibility on screen
  const [isVisible, setIsVisible] = useState(false);

  // Component visibility boundary
  const allowVisible = 400;
  
  // Effect to set an event listener on the window scroll
  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);

    // Removing the windows scroll event listener when the component unmounts
    //return () => { window.removeEventListener('scroll', onScroll) };
  }, [])

  return (
    <>
      {isVisible && 
        <div className={scrollToTopWrapper}>
          <button className={arrowButton} onClick={() => onArrowButtonClick()}>expand_less</button>
        </div>
      }
    </>
  )

  // Window scroll event listener callback
  // Component is visible when boundary is reached when scrolling down
  // Component is hidden when boundary is reached when scrolling up
  function onScroll() {
    const currentScrollY = window.scrollY;

    if(currentScrollY >= allowVisible) {  
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  // Arrow button click handler - Scroll smoothly to the top of the screen
  function onArrowButtonClick() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

export default ScrollToTop