import * as React from "react"

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image'

// Styles
import { madeInCanada } from '../styles/made-in-canada.module.css'

// Markup
const MadeInCanada = () => {
  const { t } = useTranslation();

  return (
    <div className={madeInCanada}>
      <StaticImage
        src="../images/made-in-canada.webp"
        alt="Made in Canada"
        placeholder="none"
        width={80}
      />
      <p>{t("made-in-canada")}</p>
    </div>
  )
}

export default MadeInCanada