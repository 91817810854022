import * as React from 'react'

// Plugins
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

// Styles
import { languageSelection } from '../styles/language-switcher.module.css'

// Markup
const LanguageSwitcher = () => {
  const { languages, originalPath } = useI18next();

  return (
    <>
      <ul className={languageSelection}>
        {languages.map((language, index) => {
          return (
              <li key={language}>
                <Link to={originalPath} language={language}>
                  {language.toUpperCase()}
                </Link>
                {!index && <span>/</span>}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default LanguageSwitcher