import * as React from 'react'

// Application
import Seo from '../components/seo'
import Header from './header'
import Footer from './footer'
import ScrollToTop from '../components/scroll-to-top'

// Styles
import { accentBar } from '../styles/layout.module.css'

// Markup
const Layout = ({ pageTitle, pagePath, children }) => {
  return (
    <>
      <Seo title={pageTitle} path={pagePath}/>

      {/* Shell Header */}
      <Header />
      
      {/* Accent bar */}
      <div className={accentBar}></div>

      {/* Main content */}
      <main>
        {children}
      </main>

      <ScrollToTop/>

      <Footer />
    </>
  )
}

export default Layout