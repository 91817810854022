import * as React from 'react'

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Styles
import { divider, topSectionWrapper, languageSwitcherWrapper, madeInCanadaWrapper, copyright } from '../styles/footer.module.css'

// Application
import LanguageSwitcher from '../components/language-switcher'
import MadeInCanada from '../components/made-in-canada'

// Markup
const Footer = () => {
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    <>
      <div className={divider}></div>

      <footer>
        <div className={topSectionWrapper}>
          {/* Language selection */}
          <div className={languageSwitcherWrapper}>
            <LanguageSwitcher />
          </div>

          {/* Made in Canada */}
          <div className={madeInCanadaWrapper}>
            <MadeInCanada />
          </div>
        </div>
        
        {/* Copyright */}
        <p className={copyright}>&copy; Advanced Fuel Nutrition Inc. {year} - {t("all-rights-reserved")}</p>
      </footer>
    </>
  )
}

export default Footer